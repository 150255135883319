import { useSelector, useDispatch } from "react-redux";
import { setUser } from "store/auth/userSlice";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import axios from "axios";

function useAuth() {
  const dispatch = useDispatch();
  const url = process.env.REACT_APP_API_KEY;

  const navigate = useNavigate();
  const query = useQuery();

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const signIn = async (values) => {
    try {
      const resp = await axios.post(`${url}/Auths/email-signin`, {
        email: values.email,
        password: values.password,
      });

      if (resp.data) {
        const { token, user } = resp.data;
        dispatch(onSignInSuccess(token));

        dispatch(
          setUser(
            user || {
              avatar: "",
              email: "Anonymous",
              authority: ["USER"],
            }
          )
        );

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return { status: "success", message: "" };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const signUp = async (values) => {
    try {
      const resp = await axios.post(`${url}/Auths/email-signup`, {
        organisationID: values.organisationID,
        isJoingin: values.isJoingin,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        fcmAppToken: values.fcmAppToken,
        fcmWebToken: values.fcmWebToken,
      });

      if (resp.data) {
        const { token, user } = resp.data;
        dispatch(onSignInSuccess(token));
        dispatch(
          setUser(
            user || {
              avatar: "",
              email: "Anonymous",
              authority: ["USER"],
            }
          )
        );

        const redirectUrl = query.get(REDIRECT_URL_KEY);
        navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);

        return { status: "success", message: "" };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const googleSignUp = async (
    organisationId = 0,
    googleToken,
    isJoingin = true,
    fcmAppToken = "",
    fcmWebToken = ""
  ) => {
    try {
      const resp = await axios.post(
        `${url}/Auths/google-signup`,
        {
          organisationId,
          isJoingin,
          googleToken: googleToken,
          fcmAppToken,
          fcmWebToken,
        },
        {
          headers: {
            Authorization: `Bearer ${googleToken}`,
          },
        }
      );

      if (resp.data) {
        const { token, user } = resp.data;
        dispatch(onSignInSuccess(token));
        dispatch(
          setUser(
            user || {
              avatar: "",
              email: "Anonymous",
              authority: ["USER"],
            }
          )
        );

        navigate(appConfig.authenticatedEntryPath);
        return { status: "success", message: "" };
      }
    } catch (errors) {
      console.error("Error response:", errors.response);
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const googleSignIn = async (googleToken) => {
    try {
      const resp = await axios.post(`${url}/Auths/google-signin`, {
        googleToken,
      });

      if (resp.data) {
        const { token, user } = resp.data;
        dispatch(onSignInSuccess(token));
        dispatch(
          setUser(
            user || {
              avatar: "",
              email: "Anonymous",
              authority: ["USER"],
            }
          )
        );

        navigate(appConfig.authenticatedEntryPath);
        return { status: "success", message: "" };
      }
    } catch (errors) {
      return {
        status: "failed",
        message: errors?.response?.data?.message || errors.toString(),
      };
    }
  };

  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    localStorage.removeItem("admin");
    localStorage.removeItem("access_token");
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    googleSignIn,
    googleSignUp,
    signOut: handleSignOut,
  };
}

export default useAuth;
